<template>
    <div class="billing-user-wrapper">
            <div class="d-flex pa-5 align-center">
                <p class="ma-0 text-h5 mr-5"><strong>Logs</strong></p>
            </div>
          <v-card class="pt-8 pb-8 mt-0 v-card-container">
              <div class="d-flex align-center mb-3" style="gap:5%">
                  <div class="d-flex align-center" style="width: 100%;">
                    <label>Date</label>
                    <div class="ml-3 d-flex" style="width: 100%; gap: 10px;">
                        <v-menu
                            v-model="menuFrom"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                class="date-picker-input"
                                v-model="dateFrom"
                                label="From"
                                readonly
                                dense
                                outlined
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="dateFrom"
                            no-title
                            @input="menuFrom = false"
                            ></v-date-picker>
                        </v-menu>
                        <v-menu
                            v-model="menuTo"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                class="date-picker-input"
                                v-model="dateTo"
                                label="To"
                                readonly
                                dense
                                outlined
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="dateTo"
                            no-title
                            @input="menuTo = false"
                            ></v-date-picker>
                        </v-menu>
                        <div class="search-field d-flex align-center container-search">
                            <v-text-field
                                class="w-30"
                                label="Search"
                                hide-details
                                clearable
                                @change="getRequests()"
                                dense
                                solo
                            ></v-text-field>
                            <v-btn
                                class="btn-search"
                                depressed
                                color="default"
                                elevation="2"
                                >
                                Search
                            </v-btn>
                        </div>
                    </div>
                  </div>
              </div>
              <div class="d-flex align-center mb-3 justify-lg-space-between" style="gap:5%">
                  <div class="d-flex align-center search-field">
                    <label>Company</label>
                    <v-autocomplete
                      class="ml-4"
                      hide-details
                      :items="companies"
                      v-model="query.company"
                      text="text"
                      id="value"
                      label="Select Company"
                      solo
                      dense
                      clearable
                      @change="getBillingLogs()"
                    ></v-autocomplete>
                  </div>
                  <div class="d-flex align-center">
                    <v-btn class="btn-logs mr-4">
                        Backup
                    </v-btn> 
                    <v-btn class="btn-logs">
                        History
                    </v-btn>
                  </div>
              </div>
              <div class="mt-5">
                <div class="table-wrapper">
                  <v-data-table
                    :loading="loading_table"
                    :headers="headers"
                    :items="datas.data"
                    :page.sync="page"
                    :items-per-page="10"
                    hide-default-footer
                    class="elevation-1"
                    @page-count="pageCount = $event"
                  >
                  <template v-slot:item.id="{ item, index }">
                      {{ index + 1 }}
                  </template>
                  <template v-slot:item.created_at="{ item, index }">
                      {{ formattedDate(item.created_at) }}
                  </template>
                  <template v-slot:item.company="{ item, index }">
                     {{item.company.name}}
                  </template>
                  <template v-slot:item.email="{ item, index }">
                     {{item.billing && item.billing.email_address}}
                  </template>

  
                  <template v-slot:item.action="{ item }">
                      <v-btn color="primary" icon  @click="handleShowLogs(item)">
                        <v-icon size="18">mdi-eye</v-icon>
                      </v-btn> 
                  </template>
                                                          
                
                  </v-data-table>
                </div>
                <div class="text-center v-pagination-wrapper mt-5">
                  <v-pagination
                    v-model="page"
                    @input="handlePageChange"
                    :length="datas.last_page"
                  ></v-pagination>
                </div>
              </div>
          </v-card>
          <MailLogs :open_modal="mail_logs_modal" :logs_selected="logs_selected" @handeSendSuccess="handeSendSuccess"  @handleModalHide = "mail_logs_modal = false"/>
    </div>
  </template>
  
  <script>
  import { changeFormat } from '@/utils/dateHelper';
  import { payment_status } from  '@/helpers/payment_status';
  import { insurance_status } from  '@/helpers/insurance_status';
  import MailLogs from  '../modals/mail_logs.vue';
  export default {
    components:{
      MailLogs
    },
    data () {
      return {
        mail_logs_modal : false,
        logs_selected : [],
        selected : [],
        dateFrom: null,
        dateTo: null,
        menuFrom: false,
        menuTo: false,
        loading_table : false,
        user : this.$store.getters.user.user,
        search:null,
        showeducationmodal:false,
        showeligibilitymodal:false,
        payment_status:payment_status,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        headers: [
          { text: 'Date and Time', value: 'created_at' },
          { text: 'Company', value: 'company' },
          { text: 'Email', value: 'email' },
          { text: 'Amount', value: 'amount' },
          { 
            text: 'ACTION', 
            value: 'action', 
            sortable: false,
            width: '20', 
          },
        ],
        datas:[],                             
        companies:[],
        query:{
          company : null,
          payment_status : null,
        },
      }
    },
  
    mounted(){
      this.getBillingLogs();
      this.getCompany();
    },
  
    methods:{
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },

        formattedDate(date) {
          return changeFormat(date); // Example format string
        },
        handleGetPaymentStatus(id) {
         const status_payment = payment_status.find(item=>item.value == id);
         return status_payment.text
        },
        handlePageChange() {
          this.getBillingLogs()
        },
        handleShowLogs(item) {
          this.logs_selected = item;
          this.mail_logs_modal = true;
        },
        handeSendSuccess() {
          this.$awn.success('Bill Resend Successfully'),
          this.getBillingLogs();
          this.logs_selected = [];
          this.mail_logs_modal = false;
        },
        handleGetInsuranceStatus(id) {
         const status = insurance_status.find(item=>item.value == id);
         return status.text
        },
        goTo(id){
            this.$router.push(`/application/${id}`)
        },
        goToSumarry(item){
            this.$router.push(`/billing/${item.user.client_company.company_id}/${item.billing_id}`)
        },
        getBillingLogs(){
            this.$notiflix.Loading.arrows();
             this.axios.get(`/billings/logs?page=${this.page}`).then((res)=>{
                  this.datas = res.data.data
             }).catch((err)=>{
                  console.log(err,'err')
             }).finally(()=>{
              this.$notiflix.Loading.remove();
             })
             
        },
        getCompany(){
            this.$notiflix.Loading.arrows();
             this.axios.get(`/user/get-company`).then((res)=>{
             this.companies = res.data.company.map((item)=>{
                  return{
                    value:item.id,
                    text:item.name,
                  }
              });
             }).catch((err)=>{
                  console.log(err,'err')
             }).finally(()=>{
              this.$notiflix.Loading.remove();
             })
        },
    },
    // watch: {
    //   dateFromPicker (val) {
    //     this.dateFrom = this.formatDate(this.dateFromPicker)
    //   },
    // },

  }
  </script>
  
  <style lang="scss">
   .billing-user-wrapper{
    background-color: #cdf4f6;
      height: 100%;
    }
    .table-wrapper{
      background: #fff;
      padding: 15px;
    }
    .date-picker-input{
        background-color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        border: none !important;
    }
    .container-search button.btn-search{
        background-color: #1464f6 !important ;
        span{
            color: #fff !important;
            font-weight: 600;
        }
    }
    button.btn-logs{
        background-color: #1464f6 !important ;
        span{
            color: #fff !important;
            font-weight: 600;
        }
    }
  </style>